















































import Vue from "vue";
import {validationMixin} from 'vuelidate'
import {
  required
} from 'vuelidate/lib/validators'
import {Backend} from "api-frontend";
import {authentication} from "mp-common/src/types/api/authenticationApi";
import {settings as commonSettings} from "mp-common/settings";
import {getResourceUrl} from "@/services/getResource";
import {settings} from "@/settings";
import {navigate, routes} from "@/router/routes";

export default Vue.extend({
  name: 'Login',
  mixins: [validationMixin],
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      sending: false,
      invalidCredentials: false,
      navigate: navigate,
      routes: routes,
    }
  },
  validations: {
    form: {
      username: {
        required
      },
      password: {
        required
      }
    }
  },
  methods: {
    getValidationClass(fieldName: string) {
      const field = this.$v.form[fieldName]

      if (field) {
        return {
          'md-invalid': field.$invalid && field.$dirty
        }
      }
    },
    clearForm() {
      this.$v.$reset()
      this.form.password = ""
      this.form.username = ""
    },
    async checkCredentials() {
      this.sending = true
      const result = await new Backend(settings.backendUrl, "", commonSettings.appVersion).query(authentication.login, {username: this.form.username, password: this.form.password})
      if (result) {
        localStorage.authToken = result.token;
        this.sending = false;
        const audio = new Audio(getResourceUrl("/Lasset uns beten.mp3", result.token));
        audio.volume = 0.5;
        audio.play();
        const redirect = localStorage.redirect ?? "/";
        localStorage.removeItem("redirect");
        await this.$router.push(redirect);
      } else {
        this.sending = false;
        this.invalidCredentials = true;
      }
    },
    validateLogin() {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.checkCredentials()
      }
    }
  },
  created() {
    localStorage.authToken = undefined;
  }
})
